import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { css } from "@emotion/core"

const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            title
            subtitle
            author
            date
            slug
          }
          excerpt
        }
      }
    }
  `)

  return data.allMdx.nodes.map(post => ({
    title: post.frontmatter.title,
    subtitle: post.frontmatter.subtitle,
    author: post.frontmatter.author,
    date: post.frontmatter.date,
    slug: post.frontmatter.slug,
    excerpt: post.excerpt,
  }))
}

const BlogPostList = props => {
  const posts = usePosts()

  return posts.map(post => (
    <section
      css={css`
        h1 {
          font-size: 3em;
          margin: 0.5em 0;
          text-align: left;
        }
        h2 {
          text-align: left;
          margin-bottom: 0.5em;

          a {
            color: black;
            text-decoration: none;
            word-wrap: none;
          }
          a:hover {
            text-decoration: underline;
          }
        }
        a {
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
        .date {
          color: gray;
          line-height: 1;
        }

        .read-more {
          font-weight: 700;
          text-align: right;
        }

        p:last-of-type {
          margin-bottom: 0;
        }

        @media only screen and (max-width: 768px) {
          h1 {
            font-size: 1.9em;
          }
          p {
            line-height: 1.5;
          }
        }
      `}
    >
      <Link to={`/${post.slug}`}>
        <h1>{post.subtitle}</h1>
      </Link>
      <h2>
        <Link to={`/${post.slug}`}>{post.title}</Link>
      </h2>
      <p className="date">
        Posted by {post.author} on {post.date}
      </p>
      <p>{post.excerpt}</p>
      <div className="read-more">
        <Link to={`/${post.slug}`}> Read more → </Link>
      </div>
      <hr />
    </section>
  ))
}

export default BlogPostList
