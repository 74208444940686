import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/docatra/dingusgames/src/components/layout.js";
import BlogPostList from "../components/blogPostList.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Blog`}</h1>
    <hr />
    <BlogPostList mdxType="BlogPostList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      